.assignment-name {
  font-family: "poppins light", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--basecolor);
  margin: 0;
}
.tabel-cell {
  text-overflow: unset !important;
  white-space: wrap;
  font-family: "poppins light", sans-serif;
  margin: 0;
  font-size: 14px;
}

.css-1l668ja-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  border: 0;
  outline: none;
}
.css-19950pz-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {

  outline: none;
}
