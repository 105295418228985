.course-card {
  border-radius: 0.5em;
  background-color: white;
  height: 100%;
}
.course-card > div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  gap: 0.5em;
}
.course-card > div > img {
  /* max-width: auto; */
  width: 100%;
  object-fit: cover;
}
.course-card .price {
  margin: 10px 0;
  background-color: var(--basecolor);
  /* border-radius: 0.5em; */
  padding: 0.3rem 0.5rem;
  color: white;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.course-card > div > span {
  font-family: "poppins light", sans-serif !important;
  padding: 0;
  margin: 0;
  font-size: 13px !important;
  /* color: var(--textcolor) !important; */
}

.course-card > div > p {
  font-family: "poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  font-size: 1rem !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4d44b5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4d44b5;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
