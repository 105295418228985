.Btn {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 0.8em !important;
  background-color: var(--backgroundlight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
}

.svgIcon {
  fill: var(--basecolor);
}

.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 2px solid var(--basecolor);
  border-left: 2px solid var(--basecolor);
  border-right: 2px solid var(--basecolor);
}

.Btn:hover {
  background-color: rgb(150, 94, 255);
  transition-duration: 0.3s;
}

.Btn:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

.Btn:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
