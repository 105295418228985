.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-modal-title {
  font-family: "poppins dark", sans-serif;
  font-size: 20px !important;
  color: var(--basecolor);
}

.modal-text {
  font-size: 1rem;
  font-family: "poppins dark", sans-serif;
  color: #6c757d;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.close-btn {
  background-color: #6c757d;
  border: none;
  padding: 0.5rem 1.5rem;
}

.submit-save-btn {
  background-color: #007bff;
  border: none;
  padding: 0.5rem 1.5rem;
}

.close-btn:hover,
.submit-save-btn:hover {
  opacity: 0.8;
}
.icon-box {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 5px;
}

@media (max-width: 576px) {
  .icon-box {
    font-size: 3rem;
  }

  .button-group {
    flex-direction: column;
    gap: 0.5rem;
  }
}
