.new-scan {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 0.5px solid var(--bordercolor);
    border-radius: 5rem;
    padding: 10px;
    margin: 10px 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-family: "albert light", sans-serif;
    list-style: none;
    transition: width 0.3s ease;
  }
  .upload {
    border: 2px dashed black;
    margin: 20px;
    padding: 20px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  /* .upload-table {
    padding: 20px;
  } */
  :where(.css-dev-only-do-not-override-6j9yrn).ant-modal .ant-modal-header {
    border-bottom: 1px solid var(--bordercolor) !important;
    text-align: center;
    background-color: var(--basetheme) !important;
    margin: 0 !important;
    /* padding: 20px 0px 20px 0px !important; */
    border-radius: 0 !important;
  }
  
  :where(.css-6j9yrn).ant-modal .ant-modal-title {
    color: white !important;
    border-bottom: 1px solid var(--bordercolor) !important;
    text-align: center;
    background-color: var(--basetheme) !important;
    margin: 0 !important;
    padding: 20px 0px 20px 0px !important;
    border-radius: 0 !important;
    color: white !important;
    font-size: 1.5rem;
    font-family: "Albert", sans-serif !important;
    font-weight: 500 !important;
  }
  .default-file-input {
    opacity: 0;
    width: 0;
  }
  .uploadfile-table {
    padding: 20px;
  }
  .browse-files {
    cursor: pointer;
    font-family: "albert light", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .clicktoselect {
    margin: 0;
    font-family: "albert light", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .upload-icon {
    width: 100px !important;
    height: 100px !important;
    color: var(--bordercolor) !important;
  }
  .uploadfile {
    margin-top: 10px;
    background-color: #7fecae;
    padding: 10px 10px;
  }
  .Loader {
    margin: 20px;
    padding: 20px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .uploadfile > p {
    color: black;
  }
  .starting {
    margin: 0;
    font-family: "albert", sans-serif;
    font-size: 1rem;
  }
  .save-btn {
    background-color: var(--basetheme) !important;
    color: white !important;
    text-transform: initial !important;
  }
  