.table-profile-picture {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.table-profile-picture > img {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
