.text-focus {
  font-family: "poppins light", sans-serif !important;
  height: 45px !important;
  font-size: 13px !important;
  border: 1px solid #ddd;
}
.form-control:focus {
  box-shadow: none !important;
  border: 1px solid var(--textcolor) !important;
  outline: none !important;
}
textarea.form-control {
  min-height: calc(5.5em + 0.75rem + 2px);
}
