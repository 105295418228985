.condition {
  font-family: "Poppins light", sans-serif !important;
  font-size: 14px !important;
}
.profile-form-class {
  background-color: white;
  border-radius: 0.5rem;
}
.profile-form-class > div {
  padding: 1rem 1rem;
  border-bottom: 1px solid #ddd;
}
.profile-form-class h5 {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 13px !important;
  color: var(--basecolor);
}
.profile-form:first-child > div {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}
.profile-form > div > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.addstudent {
  display: flex;
  align-self: flex-end;
}
.addstudent-btn {
  text-wrap: nowrap;
  display: inline-block !important;
  background-color: var(--basecolor) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  border: none !important;
}
