.tutor-schedule {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5em;
}
.tutor-schedule > p {
  color: var(--basecolor);
  font-family: "poppins", sans-serif !important;
  font-size: 1rem !important;
}
.tutor-schedule > span {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
}
.tutor-schedule-block {
  background-color: white;
  position: relative;
  padding: 1rem;
  border-radius: 0.5em;
}
.tutor-schedule-block::after {
  position: absolute;
  width: 5px;
  left: 0;
  content: "";
  top: 0;
  border-radius: 0.5em 0 0 0.5em;
  height: 100%;
  background-color: var(--basecolor);
}
.tutor-schedule-block p {
  color: var(--basecolor);
  font-family: "poppins", sans-serif !important;
  font-size: 15px !important;
  margin: 0;
  padding: 0;
}
.tutor-schedule-block span {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
}
.tutor-schedule-block > div > div {
  display: flex;
  flex-direction: column;
}

.tutot-block-profile {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.tutot-block-profile > img {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.view-more {
  display: flex;
  justify-content: center;
  background-color: rgba(77, 68, 181, 0.1);
  border-radius: 10em;
  border: 0.5px solid rgba(77, 68, 181, 0.1);
  padding: 10px;
}
.view-more > p {
  margin: 0;
  padding: 0;
  color: var(--basecolor);
  font-family: "poppins", sans-serif !important;
  font-size: 15px !important;
}
.visibility-icon {
  cursor: pointer;
  color: #4D44B5; 
}

.visibility-icon:hover {
  color: #FFFFFF; 
}