.banner-img {
  background-image: url("../../Assests/icon/network-connection-graphic-overlay-background1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* transform: rotateY(180deg); */
}

.form-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.register-logo {
  max-width: 100px;
  max-height: 100px;
}

.register-form-text {
  font-family: "poppins", sans-serif;
  color: var(--basecolor);
  margin: 1rem 0;
  font-size: 1.5rem;
}

.form-block>input {
  font-family: "poppins", sans-serif;
  flex-grow: 1;
  padding: 10px;
  height: 45px;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
}

.form-block>input:focus {
  outline: none;
  box-shadow: none;
}

.form-box-block>div {
  height: 100vh;
}

.submit-block {
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
  border-radius: 5rem;
  background-color: var(--basecolor) !important;
}

.submit-block>input[type="submit"] {
  background-color: var(--basecolor) !important;
  color: white;
  border-radius: 5rem;
  border: none !important;
  padding: 0.5rem 0;
  height: 40px;
}

.password-block {
  position: relative;
}

.password-block>input {
  flex-grow: 1;
  width: 100%;
  font-family: "poppins", sans-serif;
  flex-grow: 1;
  padding: 10px;
  height: 45px;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
}

.visibility {
  position: absolute;
  display: inline-block;
  right: 0px;
  top: 11px;
}

.password-icon {
  cursor: pointer;
  color: #ddd;
  font-size: 1rem;
}

.logo {
  max-width: 150px;
  max-height: 150px;
}

.form-box-block h5 {
  margin: 1rem 0;
  padding: 0;
  font-family: "poppins", sans-serif;
}

.contanier-type h3 {
  font-family: "poppins", sans-serif !important;
  color: var(--basecolor);
}

.contanier-type p {
  font-family: "poppins light", sans-serif !important;
  font-size: 1rem;
}

.contanier-type .underline {
  color: var(--basecolor);
  text-decoration: underline;
}

.contanier-type img {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 80px;
  max-height: 80px;
}

.clk-login,
.clk-link,
.clk-resend {
  color: var(--basecolor) !important;
}