.profile-container {
  display: flex;
}

.profile-details-text {
  flex: 1;
  margin-bottom: 20px;
}

.profile-details-text:last-child {
  margin-right: 0;
}

.reduced-width {
  width: 100%;
  max-width: 150px;
}

.ParentProfile {
  gap: 12rem;
}

.profile-details-texts {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
}

.profile-details-textcolor {
  font-family: "poppins dark", sans-serif !important;
  font-size: 13px !important;
  color: #4d44b5;
}

@media screen and (max-width: 768px) {
  .profile-details-text {
    flex-basis: 100%;
  }
  .profile-container {
    flex-wrap: wrap;
  }
  .reduced-width {
    max-width: none;
  }
  .ParentProfile {
    gap: 0rem;
  }
}
.password-wrapper {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 0%;
  right: 10px;
  cursor: pointer;
}

.modal-inputCourseModal {
  padding-right: 40px;
}
