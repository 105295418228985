.course-form > input {
  width: 100%;
  padding: 1rem;
  font-family: "poppins", sans-serif !important;
  font-size: 1rem;
  height: 45px;
  border: 1px solid #bfbfbf;
  border-radius: 0.2rem;
}
.course-img-banner > label > input {
  width: 0;
  opacity: 0;
}

.choose-img {
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.5rem 0.8rem;
  font-size: 13px !important;
  font-family: "poppins light" sans-serif !important;
}
.course-img-banner {
  height: 100%;
  min-height: 100px;
  width: 100%;
  padding: 1rem;
  font-family: "poppins", sans-serif !important;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}
.read-img {
  max-width: 100px;
  max-height: 100px;
  object-fit: scale-down;
}
.minus-icon-remove {
  position: absolute;
  height: 1.1rem;
  top: -5px;
  color: #fd5353;
  cursor: pointer;
  width: 1.1rem;
  right: 0;
}

.no-border {
  border: 0;
  box-shadow: none;
}
.modal-inputCourseModal {
  position: relative;
}

.modal-inputCourseModal + .input-group-append {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.custom-dropdown {
  border: none !important;
  outline: none !important;
}

.Add-course-label {
  z-index: 5;
  margin-right: -10px;
  position: relative;
}
.Add-course-label-fee {
  z-index: 1;
  margin-left: -4px;
}
