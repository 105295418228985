.tutor-profile-card > div > img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}
.tutor-profile-card {
  position: relative;
}
.tutor-profile-card > div {
  border-radius: 0.5rem;
  align-items: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tutor-profile-card > div > h5 {
  padding: 0.3rem 0.5rem;
  margin: 0;
  padding: 0;
  font-family: "poppins", sans-serif !important;
  font-size: 14px !important;
}
.profile-btn {
  color: white !important;
  background-color: var(--basecolor) !important;
  border: none !important;
  display: inline-block !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.chat-btn {
  color: white !important;
  background-color: #fb7d5b !important;
  border: none !important;
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.horizontalicon {
  cursor: pointer;
  font-size: 25px !important;
  padding: 0 !important;
  margin: 0 !important;
  color: black !important;
}
.tutor-profile-card > div > span {
  padding: 0.3rem 0.5rem;
  margin: 0;
  padding: 0;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
}
.action-data {
  position: absolute;
  left: -35px;
  top: 35px;
  z-index: 5;
}
.choose {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.choose > span:first-child {
  color: #1eba66;
  background-color: #ddfaea;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.choose > span:nth-child(2) {
  background-color: #ffeaea;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  color: #fd5353;
}
.choose > span:nth-child(3) {
  color: #1eba66;
  background-color: #ddfaea;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.choose > span {
  margin: 0;
  font-size: 13px !important;
  padding: 0;
  font-family: "poppins light", sans-serif !important;
}
.person-icon {
  color: white;
  font-size: 13px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.person-block {
  border-radius: 0.5rem;
  padding: 0.3rem 0.9rem !important;
  background-color: var(--basecolor);
}
.chat-block {
  border-radius: 0.5rem;
  padding: 0.3rem 0.9rem !important;
  background-color: #fb7d5b;
}
.horizicon {
  display: inline-block !important;
  background-color: var(--bodycolor);
  border-radius: 0.5rem;
  padding: 0.1rem 0.3rem !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
.css-1l668ja-MuiDataGrid-root
  .MuiDataGrid-columnHeader--alignCenter
  .MuiDataGrid-columnHeaderTitleContainer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #e6ebee;
}