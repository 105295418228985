.profile-banner {
  background-color: var(--basecolor);
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
  height: 60px;
}

.profile-banner1 {
  position: absolute;

  right: 100px;
  z-index: 0;
  bottom: 0;
}
.profile-banner2 {
  position: absolute;
  z-index: 1;
  bottom: 0;
}
.Complete {
  color: #1eba66;
  font-family: "poppins", sans-serif !important;
  font-size: 14px !important;
}
.Cancel {
  color: #fd5353;
  font-family: "poppins", sans-serif !important;
  font-size: 14px !important;
}
.student-details > div {
  height: 80px;
  width: 80px;
  overflow: hidden;
}

.student-details {
  position: absolute;
  z-index: 1;
  top: 12px;
  background-color: white;
  border-radius: 50%;
  left: 20px;
}
.student-profile {
  padding: 0.3rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-details {
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: white;
  padding: 2rem;
  padding-top: 2.5rem;
}
.profile-detail-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.profile-detail-block > h4 {
  font-family: "poppins", sans-serif !important;
  font-size: 1.2rem !important;
  margin: 0;
  padding: 0;
}
.profile-detail-block > span {
  font-family: "poppins dark", sans-serif !important;
  font-size: 13px !important;
  color: var(--basecolor);
  margin: 0;
  padding: 0;
}
.profile-describe-block {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}
.profile-describe-block > div {
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 1rem;
}

.profile-badge {
  position: relative;
  background-color: #fb7d5b;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-details-icon {
  position: absolute;
  color: white;
  font-size: 1.5rem !important;
}
.profile-details-text > span {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
}
.profile-details-text > p {
  font-family: "poppins dark", sans-serif !important;
  font-size: 13px !important;
  color: var(--basecolor);
}
