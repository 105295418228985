.Header {
  height: 60px;
  background-color: var(--bodycolor);
}
.outlet {
  transition: margin-left 0.5s;
  margin: 1rem 0 0 0;
  padding: 0 1rem;
}
.header-layout {
  margin-left: 0px;
  transition: margin-left 0.5s;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.header-layout.open {
  margin-left: 250px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.sidebar {
  background-color: #27579b;
  height: 100vh;
  margin-top: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.sidebar-layout {
  position: fixed;
  width: 0;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
}
.sidebar-layout::-webkit-scrollbar {
  width: 3px;
}
.sidebar-layout::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
}
.sidebar-block {
  padding: 1rem 0;
  /* height: 30px; */
  /* background-color: #e6ebee; */
}
.sidebar-block > div > h5 {
  font-family: "poppins dark", sans-serif !important;
  color: white;
  text-wrap: nowrap;
  font-size: 1rem !important;
  margin: 0 !important;
  padding: 0 !important;
}
.sidebar-block > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.sidebar-block > div::after {
  height: 60px;
  width: 60px;
  content: "";
  z-index: 0;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  display: flex;
  left: 8px;
  top: 12px;
}
.sidebar-layout::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.sidebar-layout.open {
  background-color: white;
  width: 250px !important;
}
.route-layout {
  margin: 0;
  padding: 5px 0px;
}
.bottomroute {
  position: absolute;
  bottom: 0;
}

/* .route-layout:last-child::after {
  position: absolute;
  content: "";
  background-color: #ddd;
  height: 1px;
  left: 40%;
  width: 50px;
  top: -20px;
} */

.link-route {
  text-decoration: none !important;
}
.link-route > div > p {
  color: black;
  display: inline-block;
  white-space: nowrap;
  font-family: "poppins";
  font-size: 15px !important;
  list-style: none;
  padding: 6px 10px;
}
.outlet.open {
  margin-left: 250px;
  transition: margin-left 0.5s;
}
.Header-menu {
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block;
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  border: 0.5px solid #ddd !important;
  border-radius: 5px;
  padding: 1px !important;
}

.Header > div {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.footer {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.footer-copyright {
  font-family: "poppins light", sans-serif;
  font-size: 0.8rem;
}
.link-route > div {
  margin: 0 15px 0 15px;
}
.route-block.active {
  background-color: #6860c0;
}
.link-name {
  text-wrap: nowrap;
}
.route-block {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 1rem;
  font-size: 13px;
  font-family: "poppins", sans-serif;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  color: hsla(0, 0%, 100%, 0.85);
}
.line-item-block {
  margin-left: 3.5rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
.line-item-block.open {
  max-height: 350px;
}

.li-name {
  text-wrap: nowrap;
  line-height: 30px;
  list-style: none;
  font-weight: 100;
  font-size: 12px;
  font-family: "poppins light", sans-serif;
}
.li-name:hover {
  color: hsla(0, 0%, 100%, 0.85);
}
.nav-link-li {
  text-decoration: none !important;
  color: white !important;
}

.profile-menu {
  position: relative;
}

.footer > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.footer p {
  font-family: "poppins light", sans-serif;
  font-size: 0.8rem;
  margin: 0;
}
.footer-layout {
  margin-top: auto;
  padding-top: 2rem;
  transition: margin-left 0.5s;
}
.footer-layout.open {
  margin-left: 250px;
  transition: margin-left 0.5s;
}
.profile-block {
  position: absolute;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: inline-block;
  padding: 8px 15px;
  right: 0px;
  top: 30px;
}
.profile-block > div {
  white-space: nowrap;
  cursor: pointer;
  color: var(--textcolor);
  font-family: "poppins", sans-serif;
  font-size: 12px;
  line-height: 30px;
  border-bottom: 1px solid var(--backgroundlight);
}
.active > .active-path {
  padding: 0;
  border-radius: 0.3rem;
  background-color: #6860c0;
}
.sidebar-logo {
  /* border-radius: 50%; */
  max-width: 50px;
  max-height: 50px;
  margin-left: 1rem;
  z-index: 1;
}

@media screen and (max-width: 426px) {
  .sidebar-layout.open {
    width: 250px;
    margin-top: 50px;
  }
  .sidebar-layout.close {
    width: 0;
    margin-top: 50px;
  }
  .header-layout.open {
    margin-left: 0px;
  }
  .input-block {
    flex-grow: 1;
  }
  .input-flex {
    flex-grow: 1;
  }

  .footer-layout.open {
    margin-left: 0px;
    transition: margin-left 0.5s;
  }

  .outlet.open {
    transition: margin-left 0.5s;
    margin-left: 0;
  }
}
/* ////////////////////////--ADMIN---////////////////////// */
.dashboard > h6 {
  font-family: "poppins", sans-serif !important;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: var(--textbasecolor) !important;
}
.dashboard-text {
  color: #303972 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-family: "poppins dark", sans-serif !important;
  margin: 0;
  padding: 0;
}
.block-box {
  position: relative;
  height: 180px;
  background-color: #ffffff;
  border-radius: 0.2rem;
  margin: 0.2rem 0;
  font-family: "poppins", sans-serif;
  box-shadow: none !important;
}
.block-card {
  padding: 1.5rem 1rem;
}
.aje {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 2.5px;
  top: 2.5px;
  background-color: rgb(74 222 128);
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.aje1 {
  position: relative;
  background-color: rgb(74 222 128 / 10%);
  height: 15px;
  border-radius: 50%;
  width: 15px;
}
.chart-box {
  background-color: #ffffff;
  border-radius: 0.2rem;
  margin: 0.2rem 0;
  font-family: "poppins", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.chart-box1 {
  background-color: #ffffff;
  border-radius: 1rem;
  margin: 0.2rem 0;
  font-family: "poppins", sans-serif;
}

.block-card > div > :first-child {
  font-size: 16px;
  font-family: "poppins", sans-serif;
  color: var(--textcolor);
}

.block-value {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.block-value > :nth-child(2) {
  background-color: var(--backgroundlight);
  border-radius: 0.4rem;
  padding: 0.2rem;
  font-size: 14px;
}
.chart-box > div > :nth-child(2) {
  font-size: 1rem;
  color: var(--textcolor) !important;
}
.block-chart-data > :first-child {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1.5rem 1rem;
}
.block-chart-data > :nth-child(2) {
  position: absolute;
  margin: 1.5rem 1rem;
  bottom: 0;
  right: 0;
}
.monitorchart {
  height: 70px;
}
.up-arrow {
  color: var(--uparrow);
}
.block-card .uparrow {
  color: var(--uparrow);
  margin: 0.3rem;
}
.block-card .downarrow {
  color: var(--downarrow);
  margin: 0.3rem;
}
.preview-card {
  margin: 0.2rem 0;
  font-family: "poppins", sans-serif;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
  padding: 10px 20px;
}
.preview-header > div > div {
  padding: 5px 0px;
  font-size: 14px !important;
  color: var(--textcolor);
  font-family: "poppins", sans-serif !important;
}
.preview-header > div > div > div {
  padding: 3px 0px;
  font-size: 14px !important;
  font-family: "poppins", sans-serif !important;
  color: var(--textcolor);
}
.progressBar {
  min-width: 100px;
  height: 10px !important;
  border-radius: 3px !important;
}
.preview-header > .box-bar {
  display: flex;
  flex-grow: 1;
}
.box-color {
  height: 12px;
  width: 12px;
  border-radius: 0.2rem;
}
.good-performace {
  background-color: #ddfaea;
  border-radius: 0.3rem;
  padding: 0.3rem;
  color: #1eba66;
  font-family: "poppins", sans-serif !important;
  height: 30px;
}

.bad-performace {
  background-color: #ffeaea;
  border-radius: 0.3rem;
  padding: 0.3rem;
  color: #fd5353;
  font-family: "poppins", sans-serif !important;
  height: 30px;
}
.row-block {
  background-color: white;
  border-radius: 0.5rem;
}
.block-icon {
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.block-describe > h5 {
  font-family: "Poppins light", sans-serif !important;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.block-describe > p {
  font-family: "Poppins", sans-serif !important;
  font-size: 1.5rem;
  color: var(--basecolor);
  margin: 0;
  padding: 0;
}
.block-element {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  padding: 3rem;
}
.product > h6 {
  font-family: "poppins", sans-serif;
  font-size: 1rem;
}
.multi-select-dropdown {
  background-color: white;
  width: 100%;
  max-height: 180px;
  overflow: scroll;
  overflow-x: auto;
  position: absolute;
  border: 1px solid #ddd;
  left: 0;
  border-radius: 0.375rem;
  top: 48px;
  z-index: 1;
}
.dropdown-list {
  padding: 0;
  line-height: 1px !important;
  padding-left: 10px;
  margin: 0;
}
.dropdown-list > li {
  padding: 0 !important;
  height: 30px;
  margin: 0 !important;
}
.checkbox-input {
  accent-color: var(--basecolor);
  border: 1px solid black !important;
  border-radius: 2rem !important;
}
.duration-name {
  font-family: "Poppins light", sans-serif !important;
  font-size: 14px;
  color: var(--basecolor);
  margin: 0;
  padding: 0;
}
.multi-select {
  position: relative;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  height: 45px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}
.table-box {
  padding: 1rem 0;
  border-radius: 0.5rem;
  background-color: white !important;
  box-shadow: none;
}
.table-box > div {
  border: none !important;
}

.css-az8st9-MuiDataGrid-root {
  border-style: none !important;
}
.base-btn {
  color: black !important;
  background-color: white !important;
  font-family: "poppins light", sans-serif !important;
  border-color: white !important;
  box-shadow: "none";
}
.good-status {
  font-family: "poppins light", sans-serif !important;
  padding: 0;
  position: relative;
  margin: 0;
  left: 10px;
}
.good-status::after {
  content: "";
  height: 10px;
  border-radius: 50%;
  background-color: #1eba66;
  position: absolute;
  top: 5px;
  left: -15px;
  width: 10px;
}
.bad-status {
  font-family: "poppins light", sans-serif !important;
  padding: 0;
  position: relative;
  margin: 0;
  left: 10px;
}
.bad-status::after {
  content: "";
  height: 10px;
  border-radius: 50%;
  background-color: #fd5353;
  position: absolute;
  top: 5px;
  left: -15px;
  width: 10px;
}
.product > h6 {
  font-family: "poppins", sans-serif;
  font-size: 1rem;
}
.table-boxed {
  background-color: #ffffff;
  margin: 0.2rem 0;
  font-family: "poppins", sans-serif;
}

.search-iconbtn {
  margin-left: 10px !important;
  background-color: var(--backgroundlight) !important;
}
.student-name {
  font-family: "poppins", sans-serif;
  color: var(--basecolor);
}
.action-icon-box {
  cursor: pointer;
  background-color: var(--backgroundlight);
  border-radius: 0.4rem;
  padding: 0 5px;
  height: 20px;
}
.action-icon {
  height: 20px;
  background-position: center;
  background-size: cover;
  width: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("./Assests/icon/ellipsis.svg");
}
.input-flex {
  position: relative;
}
.input-block {
  border: 1px solid #ddd !important;
  height: 40px;
  padding-left: 3rem;
  border-radius: 0.3rem;
}
.input-block:focus {
  box-shadow: none !important;
  outline: none !important  ;
}
.search-icon-block {
  position: absolute;
  left: 10px;
  font-family: "poppins", sans-serif;
  font-size: 14px;
}
.new-add-btn {
  cursor: pointer;
  background-color: var(--basecolor);
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
}
.modal-title {
  font-family: "poppins dark", sans-serif;
  font-size: 14px !important;
  color: var(--basecolor);
}
.modal-label {
  font-family: "poppins light", sans-serif;
  color: var(--textcolor);
  font-size: 14px;
}
.modal-input {
  font-family: "poppins light", sans-serif !important;
  height: 45px !important;
  font-size: 13px !important;
  border: 1px solid #ddd;
}
.modal-inputCourseModal {
  font-family: "Poppins Light", sans-serif !important;
  font-size: 13px !important;
  border: 1px solid #ddd;
}

.modal-inputCourseModal:focus,
.modal-inputCourseModal-textarea:focus {
  box-shadow: none !important;
  border: 1px solid var(--textcolor) !important;
  outline: none !important;
}

.modal-inputCourseModal::selection,
.modal-inputCourseModal-textarea::selection {
  background-color: var(
    --basecolor
  ) !important; /* Use background-color for selection */
}

.close-btn {
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  background-color: #ffeaea !important;
  display: inline-block !important;
  height: auto !important;
  padding: 0.5rem 0.8rem !important;
  color: #fd5353 !important;
}
.modal-save-btn {
  font-size: 12px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  background-color: var(--basecolor) !important;
  border-radius: 0.3rem !important;
  padding: 0.8rem 0.5 !important;
  color: white !important;
}
.modal-input-textarea {
  height: 100%;
}

.modal-input:focus,
.modal-input-textarea:focus {
  box-shadow: none !important;
  border: 1px solid var(--textcolor) !important;
  outline: none !important;
}
.modal-input::selection,
.modal-input-textarea::selection {
  border: 1px solid var(--basecolor) !important;
}
.selectInput {
  height: 20px;
  color: var(--textcolor);
  border-radius: 5px !important;
  border: 1px solid var(--textcolor);
  box-shadow: none !important;
  outline: none !important;
  font-family: "poppins", sans-serif;
  font-size: 14px;
}

.showentries {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.box-block {
  z-index: 1;
  position: absolute;
  border-radius: 0.5rem;
  background-color: white;
  display: inline-block;
  padding: 8px 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.box-block > div {
  font-family: "poppins", sans-serif !important;
  font-size: 13px;
  color: var(--textcolor);
  cursor: pointer;
  line-height: 30px;
  border-bottom: 1px solid var(--backgroundlight);
}
.showentries > p {
  color: var(--textcolor) !important;
  font-family: "poppins", sans-serif !important;
  font-size: 12px !important;
  padding: 0;
  margin: 0;
}
.register {
  width: 100%;
  height: 50px;
}
.register > h6 {
  font-family: "poppins", sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: black;
  position: relative;
}
.register > h6::after {
  content: "";
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -3px;
  position: absolute;
  background-color: var(--basecolor);
}
.title-card {
  font-family: "poppins", sans-serif;
  font-size: 1rem;
}
.register-form > form > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.register-form label {
  display: block;
  font-family: "poppins", sans-serif !important;
  font-size: 1rem;
  color: #505050;
}

.register-form input {
  border-color: #ddd;
  /* width: 100%;
  padding: 1rem;
  font-family: "poppins", sans-serif !important;
  font-size: 1rem;
  height: 45px;
  border: 1px solid #bfbfbf;
  border-radius: 0.2rem; */
}
.register-form input:focus {
  outline: none;
  box-shadow: none;
}
.dropdown-list > li > p {
  margin: 0;
  padding: 0;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px;
}
.multi-select-tag {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px;
}
.title-tag {
  color: var(--basecolor);
  background-color: var(--backgroundlight);
  padding: 3px 6px;
  border-radius: 0.5rem;
}
.partition {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 1rem;
}
.partition > div {
  margin: 0.5rem 0;
  flex-grow: 1;
}
.register-form {
  margin-top: 20px;
}
.childer-from {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.5rem;
  padding: 1rem;
}
.form-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 0.5rem;
}
.form-card > div {
  padding: 0.5rem 1rem;
  flex-grow: 1;
}
.form-card > div > input {
  width: 100%;
  padding: 1rem;
  font-family: "poppins", sans-serif !important;
  font-size: 1rem;
  display: inline-block;
  height: 45px;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
}

.down-icon {
  cursor: pointer;
}
.add-btn {
  cursor: pointer;
}
.plus-icon {
  width: 1.3rem;
}
.add-btn {
  display: inline-block;
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  font-family: "poppins", sans-serif;
  font-size: 1rem;
}

.formCollapse {
  max-height: 650px;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: max-height 0.5s ease;
}

.formCollapse > :nth-child(2) {
  flex-grow: 1;
}

.continue {
  background-color: var(--btncolor);
}
.previewBox {
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.previewBox p {
  margin: 5px 0;
  padding: 0;
  font-family: "poppins", sans-serif;
  font-size: 1rem;
}
.previewBox span {
  margin: 5px 0;
  padding: 0;
  font-family: "poppins light", sans-serif;
  font-size: 15px;
}
.performance {
  background-color: #ddfaea;
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
  color: #1eba66;
  font-size: 10px;
  font-family: "poppins", sans-serif !important;
}
.profile-card-btn-prf {
  color: white;
  margin: 1rem 0;
  cursor: pointer;
  display: inline-block;
  background-color: var(--basecolor);
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
}
.profile-card-btn-rem {
  cursor: pointer;
  background-color: #ffeaea;
  border-radius: 0.3rem;
  padding: 0.3rem;
  color: #fd5353;
  margin: 1rem 0;
  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
}
.tutor-profile {
  max-width: 90px;
  max-height: 90px;
  object-fit: fill;
}
.tutor-block {
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 0.8rem;
}

.profile-text {
  font-family: "poppins", sans-serif;
  font-size: 14px;
}
.add-new {
  background-color: var(--basecolor);
  display: inline-block;
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  padding: 0.5rem 0.6rem;
  border-radius: 0.5rem;
}
.search-inputbox {
  border-radius: 0.3rem;
  border: 1px solid #ddd;
  height: 35px;
}
.file-upload {
  opacity: 0 !important;
  width: 0 !important;
  margin: 0;
  padding: 0;
}
.span-text {
  color: var(--textcolor) !important;
  font-family: "poppins light", sans-serif !important;
  font-size: 12px !important;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.select-block-box {
  border: 1px solid var(--basecolor);
}
.upload-block {
  width: 100%;
  height: 195px;
  padding: 3rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.file-icon {
  font-size: 3rem !important;
  color: var(--basecolor) !important;
}
.upload-file {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  gap: 0.5rem;
}
.upload-file span {
  font-family: "poppins light", sans-serif !important;
  font-size: 12px !important;
}
/* .upload-label {
  height: 110px !important;
} */
.upload-icon {
  max-width: 50px;
  max-height: 50px;
  margin: 0;
  padding: 0;
}

/* /////////////////////---TUTOR---/////////////////////// */
.tutor-profile-form {
  display: flex;
  gap: 1rem;
}

.tutor-profile-form > div:nth-child(2) {
  flex-grow: 3;
}
.tutor-profile-form > div:nth-child(1) {
  margin: 1rem 0;
  padding: 1rem;
  flex-grow: 1;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutor-profile-form > div:nth-child(1) > div:first-child {
  position: relative;
}
.tutor-profile-form > div:nth-child(1) p {
  font-family: "poppins", sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
}
.tutor-profile-form > div:nth-child(1) span {
  font-family: "poppins light", sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
}
.tutor-profile-img {
  max-width: 100px;
  max-height: 100px;
}
.course-form {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
}
.course-form > label {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  color: var(--textcolor) !important;
  margin: 0;
  padding: 0;
}
.submit-save-btn {
  border: none;
  color: white;
  border-radius: 0.5em;
  background-color: var(--basecolor) !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  display: inline-block !important;
  height: auto !important;
  padding: 0.5rem 0.8rem !important;
}
input {
  height: 40px;
  border: 1px solid #ddd;
}
textarea {
  margin: 0;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  padding: 1rem;
  height: 100px !important;
  border-radius: 0.5rem !important;
  border: 1px solid #ddd;
}
textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}
input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.custom-margin {
  margin-bottom: 6px !important;
  margin-top: 5px !important;
  margin-right: 8px !important;
}

.css-1niuphq-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1niuphq-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}