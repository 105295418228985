#viewerContainer {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
}

.annotation-box {
  border: 1px solid black;
  background-color: white;
  padding: 4px;
  cursor: pointer;
}

.annotation-icon {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: #000000;
  margin-top: -34px;
  margin-left: -6px;
}

.icon-background {
  color: #f4a907;
}

.sticky-note {
  position: absolute;
  background-color: #fffbcc;
  width: 50px;
  padding: 15px;
  border: 2px solid #f4a907;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

.sticky-note::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f0c419;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.highlight-text {
  background-color: yellow;
  border-bottom: 2px solid orange;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.pdfViewer {
  overflow: hidden !important;
}
.pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: 1px auto -8px auto;
  position: relative;
  overflow: visible;
  border: 9px solid transparent;
  background-clip: content-box;
  background-color: white;
  overflow: hidden !important;
}

/* .bi-x-circle-fill {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: red;
  font-size: 16px;
  z-index: 10;
  display: none; 
} */

.annotation-button {
  display: inline-block;
  position: relative;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.annotion-img {
  width: 20px;
}

.active-icon {
  margin-top: 5px;
  font-size: 0.5em;
  color: blue;
}

.annotation-button.active .active-icon {
  color: blue;
}

.annotation-button.active {
  background-color: #f0f0f0;
  border-radius: 4px;
}
.custom-tooltip {
  background-color: white !important;
  color: black !important;
  border: 1px solid #ccc !important;
}
.pdf-container {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
}

.pdf-viewer-wrapper {
  flex: 1;
  /* overflow-y: auto; */
  position: relative;
}

.viewer-container {
  position: relative;
}

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid blue; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Ensure responsiveness */
/* @media (max-width: 768px) {
  .pdf-container {
    min-height: 60vh;
  }
}

@media (max-width: 480px) {
  .pdf-container {
    min-height: 50vh;
  }
} */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; 
}

.pdf-generate .fa-icon {
  margin-right: 8px;
}
