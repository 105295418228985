body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  z-index: 1000;
  transform: translate(-50%, -100%);
  white-space: nowrap;
}

.css-1niuphq-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1niuphq-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
.css-1hz4zdc-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable {
  background-color: #e6ebee;
  outline: none !important;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fefbfa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #27579B;
  border-radius: 5px;
  min-height: 10px !important;
  max-height: 4px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #27579B;
}
