/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 35% !important;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
} */

.input-modal-content {
    padding: 20px;
    border-radius: 5px;
    width: 35% !important;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-actions button {
    margin-left: 10px;
}

.close-btn {
    background-color: red;
    /* or any color you prefer */
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.submit-save-btn {
    background-color: green;
    /* or any color you prefer */
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

textarea {
    margin: 0;
    font-family: "poppins light", sans-serif !important;
    font-size: 13px !important;
    padding: 1rem;
    height: 100px !important;
    border-radius: 0.5rem !important;
    border: 1px solid #ddd;
    width: 100%;
}