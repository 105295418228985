* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "albert light";
  src: url("../src/Fonts/Albert/static/AlbertSans-Regular.ttf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "albert";
  src: url("../src/Fonts/Albert/static/AlbertSans-Medium.ttf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("../src/Fonts/Poppins-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins light";
  src: url("../src/Fonts/Poppins-Light.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins dark";
  src: url("../src/Fonts/Poppins-SemiBold.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inkfree";
  src: url("../src/Fonts/Inkfree.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  /* font-display: swap; */
}

:root {
  --backgroundtheme: #faab35;
  --uparrow: rgb(130, 214, 22);
  --downarrow: #ef4444;
  --textcolor: #a098ae;
  --textbasecolor: black;
  --basecolor: #4d44b5;
  --btncolor: #fdb922;
  --backgroundlight: #ede9f1;
  --routecolor: white;
  --bordercolor: rgb(219, 224, 235);
  --bodycolor: #e6ebee;
  --draft: #c26401;
}

body {
  /* min-height: 100% !important; */
  background-color: var(--bodycolor) !important;
}

.online {
  margin-bottom: 100px;
  background-color: green;
}

.online p {
  display: flex;
  justify-content: center;
  color: white;
  padding: 5px;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  margin: 0;
  cursor: pointer;
}

.pdf-generate {
  margin-bottom: 10px;
  font-size: 12px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  background-color: var(--basecolor) !important;
  border-radius: 0.3rem !important;
  padding: 0.3rem 5px !important;
  color: white !important;
}

.offline {
  z-index: 100;
  background-color: black;
}

.offline p {
  display: flex;
  justify-content: center;
  color: white;
  padding: 5px;
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  margin: 0;
}

.css-az8st9-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: white !important;
}

.add-from-block {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  gap: 1rem;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row-reverse;
}

.add-from-block > div {
  flex: 1;
}

.add-from-block > div > div {
  display: flex;
  flex-direction: column;
}

.add-from-block label {
  margin-top: 0.5rem !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  color: var(--textcolor) !important;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.add-from-block input {
  padding: 1rem;
  display: inline-block;
  height: 45px;
  border: 1px solid #bfbfbf;
  border-radius: 0.2rem;
  font-family: "poppins light", sans-serif !important;
  height: 45px !important;
  font-size: 13px !important;
  border: 1px solid #ddd;
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  border-radius: 0 !important;
  /* color: var(--basecolor) !important; */
  /* background-color: var(--backgroundlight); */
}

/* .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #4d44b5 !important;
  color: white !important;
}
.css-2gftvx.Mui-selected {
  background-color: #4d44b5 !important;
  color: white !important;
  font-family: "poppins", sans-serif !important;
  border: none !important;
} */
.student-table-box {
  height: 250px;
  overflow: scroll;
  overflow-x: auto;
}

.student-table-box::-webkit-scrollbar {
  width: 6px;
}

.student-table th {
  font-family: "poppins", sans-serif;
  font-size: 15px;
  color: var(--basecolor);
  font-weight: 100;
}

.student-table td {
  font-size: 14px;
  font-family: "poppins light", sans-serif !important;
}

.css-2gftvx {
  font-family: "poppins", sans-serif !important;
  border: none !important;
}

input::-ms-reveal {
  display: none !important;
}

.modal-footer-modal {
  border: none !important;
}

/* .MuiDataGrid-row.Mui-selected {
  background-color: transparent !important;
  border: none !important;
} */
.MuiDataGrid-withBorderColor {
  border: none !important;
}

.css-az8st9-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: none !important;
}

.modal-header .btn-close {
  font-size: 10px !important;
}

.modal-header .btn-close:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border: none !important;
  border-radius: 0.5rem !important;
  font-family: "poppins" !important;
  font-size: 1rem !important;
} */
.continue {
  cursor: pointer;
  transition: all 0.2s;
  padding: 5px 10px;
  border-radius: 100px !important;
  background: var(--basecolor);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 1rem !important;
  font-family: "poppins", sans-serif !important;
}

.continue > svg {
  width: 25px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.continue:hover svg {
  transform: translateX(5px);
}

.continue:active {
  transform: scale(0.95);
}

/* //checkdbox// */
.ui-checkbox {
  height: 15px !important;
  width: 15px !important;
  --primary-color: var(--basecolor);
  --secondary-color: #fff;
  --primary-hover-color: #4096ff;
  --checkbox-diameter: 25px;
  --checkbox-border-radius: 0.3em;
  --checkbox-border-color: #d9d9d9;
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  --checkmark-size: 1.1;
}

.ui-checkbox,
.ui-checkbox *,
.ui-checkbox *::before,
.ui-checkbox *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style)
    var(--checkbox-border-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.ui-checkbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5)
    var(--primary-color);
  box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  border-radius: inherit;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ui-checkbox::before {
  top: 40%;
  left: 50%;
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  border-right: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

/* actions */

.ui-checkbox:hover {
  border-color: var(--primary-color);
}

.ui-checkbox:checked {
  background: var(--primary-color);
  border-color: transparent;
}

.ui-checkbox:checked::before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(45deg)
    scale(var(--checkmark-size));
  -ms-transform: translate(-50%, -50%) rotate(45deg)
    scale(var(--checkmark-size));
  transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ui-checkbox:active:not(:checked)::after {
  -webkit-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: none;
  opacity: 1;
}

.tooltipbox .svg {
  fill: var(--basecolor);
}

.tooltipbox {
  cursor: pointer;
  background-color: var(--backgroundlight);
  transition-duration: 0.3s;

  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0.5rem !important;
  border: 0px solid transparent !important;
  /* background-color: transparent !important; */
  border-radius: 0.8em !important;
  /* transition: all 0.2s linear !important; */
}

.box-action {
  cursor: pointer;
}

.date-picker-box {
  padding: 0 !important;
}

.tooltipbox {
  position: relative;
  display: inline-block;
}

.tooltipbox .tooltiptext {
  visibility: hidden;
  width: 4em;
  background-color: rgba(0, 0, 0, 0.253);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 110%;
}

.tooltipbox:hover {
  background-color: rgb(150, 94, 255);
}

.tooltipbox:hover .svg {
  fill: white;
  animation: lr 1s linear infinite;
}

.tooltipbox .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.253) transparent transparent;
}

.custom-modal {
  --bs-modal-width: 1000px !important;
}

.tooltipbox:hover .tooltiptext {
  visibility: visible;
}

/* ///edit/// */
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 0.8em !important;
  cursor: pointer;
  background-color: var(--backgroundlight);
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11); */
}

.button:hover {
  background-color: rgb(150, 94, 255);
}

.button:hover .stroke-color {
  stroke: white;
}

.button:hover .svg-icon {
  fill: rgb(150, 94, 255);
  animation: lr 1s linear infinite;
}

@keyframes lr {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-1px);
  }

  75% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

/* ///alert//// */
.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
  height: 8em;
  width: 8em;
  border: 1em solid var(--basecolor);
  border-radius: 50%;
  animation: loader_79178 2s linear infinite;
}

.loader::after {
  opacity: 0;
  animation-delay: 1s;
}

@keyframes loader_79178 {
  0% {
    border: 1em solid var(--basecolor);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    border: 0 solid var(--basecolor);
    transform: scale(0.3);
    opacity: 0;
  }
}

.cssbuttons-io-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 17px;
  padding: 12px;
  color: white;
  /* animation: loader_79178 2s linear infinite; */

  background-color: var(--backgroundlight);
  /* background: linear-gradient(0deg, rgb(120, 47, 255) 0%, #e6ebee 100%); */
  border: none;
  /* box-shadow: 0 0.7em 1.5em -0.5em rgb(184, 146, 255); */
  letter-spacing: 0.05em;
  border-radius: 0.8em !important;
}

.cssbuttons-io-button .upload-svg {
  fill: var(--basecolor);
}

.cssbuttons-io-button:hover .upload-svg {
  fill: white;
  animation: lr 1s linear infinite;
}

.cssbuttons-io-button:hover {
  background-color: rgb(150, 94, 255);
}

.MuiDataGrid-cellContent {
  overflow: hidden;
  text-overflow: unset !important;
}

/* //card// */
.reactfiles-cards {
  padding: 5px;
  /* width: 190px; */
  /* height: 254px; */
  /* margin: 0 auto; */
  /* background-color: var(--bodycolor); */
  border: 1px solid #ddd;
  border-radius: 8px;
  z-index: 1;
}

.box-block-shadow {
  z-index: 10;
}

.tools {
  display: flex;
  align-items: center;
}

.tab-block .nav-link {
  color: black !important;
}

.tab-block:focus {
  outline: none !important;
  box-shadow: none !important;
}

.tab-block {
  font-family: "poppins", sans-serif !important;
  font-size: 1rem !important;
  color: var(--basecolor) !important;
}

.tab-block .active {
  color: var(--basecolor) !important;
}

.payment-due {
  font-family: "poppins", sans-serif !important;
  font-size: 1rem !important;
  color: black !important;
}

.MuiDataGrid-row:hover {
  background-color: white !important;
}

.circle {
  padding: 0 4px;
}

.card__content {
  font-family: "poppins light", sans-serif !important;
  font-size: 12px !important;
}

.box {
  display: inline-block;
  align-items: start;
  width: 5px;
  height: 5px;
  padding: 1px;
  border-radius: 50%;
}

.red {
  background-color: var(--backgroundlight);
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: var(--basecolor);
}

.preview-eye {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 15px;
  padding: 12px;
  color: white;
  background-color: var(--backgroundlight);
  border: none;
  letter-spacing: 0.05em;
  border-radius: 0.8em !important;
}

.preview-eye .eye {
  fill: var(--basecolor);
}

.preview-eye:hover .eye {
  fill: white;
  animation: lr 1s linear infinite;
}

.preview-eye:hover {
  background-color: rgb(150, 94, 255);
}

.pdf-content * {
  user-select: text !important;
}

.text-wrap {
  white-space: normal !important;
  word-wrap: break-word;
}

.textcolor {
  color: #7c7c7c;
}

.text-style {
  font-family: "poppins", sans-serif;
}

.css-1l668ja-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  border: 0;
  outline: none;
}

.assignment-title {
  font-size: 1.5em;
  font-weight: bold;
}

.submitted-by {
  font-size: 1em;
  color: #555;
}
.css-1niuphq-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1niuphq-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
.css-1hz4zdc-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable {
  background-color: #e6ebee;
  outline: none !important;
}