.files-description {
  text-align: left;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__content span:first-child {
  color: var(--basecolor);
}
.submit-save-btn-draft {
  background-color: var(--draft);
  color: white;
  border-radius: 0.5em;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  display: inline-block !important;
  height: auto !important;
  padding: 0.5rem 0.8rem !important;
}
.assignment-name-text {
  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: var(--basecolor);
  text-underline-offset: 5px;
}
.attached-files-container {
  position: relative;
  width: 100%;
}

.attached-files-label {
  flex-shrink: 0;
  margin-right: 10px;
}

.attached-files-input-wrapper {
  position: relative;
  flex-grow: 1;
}

.attached-files-input {
  width: 100%;
  padding-right: 2rem;
}

.download-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
