.profile-card {
  /* height: 100vh; */
  background-color: white;
  border-radius: 0.5rem;
}
.profile-form {
  /* height: 100vh; */
  background-color: white;
  border-radius: 0.5rem;
}
.profile-form > h5 {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 13px !important;
  padding: 1rem 1rem;
  color: var(--basecolor);
  border-bottom: 1px solid #ddd;
}
.profile-form > form > div {
  align-items: baseline;
  flex-wrap: wrap;
  display: flex;
  padding: 1rem;
  gap: 1rem;
}
.profile-form > form > div > div {
  min-width: 200px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.profile-form label {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  color: var(--textcolor) !important;
}

.profile-form select {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  height: 40px !important;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}
/* Chrome, Safari, Edge, Opera */
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spinners[type="number"] {
  -moz-appearance: textfield;
}

.profile-form input {
  font-family: "poppins light", sans-serif;
  font-size: 13px !important;
  border: 1px solid #ddd !important;
  border-radius: 0.375rem !important;
}
.profile-form input[type="checkbox"] {
  font-family: "poppins light", sans-serif;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}

.profile-form input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.profile-save {
  border: none !important;
  background-color: var(--basecolor) !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.save-border {
  border-top: 1px solid #ddd;
}
.profile-card > div > img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}
.profile-card > div {
  position: relative;
}
.profile-card {
  height: 100%;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.profile-card p {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  margin: 0;
  padding: 0;
}
.profile-card span {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  margin: 0;
  padding: 0;
}
.camera-icon-block {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  bottom: 0px;
  background-color: #395ee2;
  border-radius: 100%;
  padding: 0.3rem;
  color: white;
  border: 2px solid white;
}
.camera-icon {
  cursor: pointer;
  color: white !important;
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 15px !important;
}
.hide-input {
  width: 0;
  opacity: 0;
}
