.details-banner {
  background-color: white;
  height: 500px;
  border-radius: 5px;
}
.details-banner > div {
  position: relative;
  background-image: url("../../../../../Assests/icon/2148200133.jpg");
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px 5px 0 0;
}
.details-profile {
  bottom: -70px;
  left: 10px;
  position: absolute;
  height: 150px;
  width: 150px;
  overflow: hidden;
}
.details-profile > img {
  border: 10px solid white;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
