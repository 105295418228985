.profile-parent-card {
  width: 15rem;
  /* height: 100vh; */
  background-color: white;
  border-radius: 0.5rem;
}
.profile-form {
  /* height: 100vh; */
  background-color: white;
  border-radius: 0.5rem;
}
.profile-parent-form {
  background-color: white;
  border-radius: 0.5rem;
}
.registration-block {
  background-color: white;
  border-radius: 0.5rem;
  margin: 1rem 0;
}
.minus-block {
  padding: 0 1rem;
}
.minus-icon {
  height: 1.1rem;
  color: #fd5353;
  cursor: pointer;
  width: 1.1rem;
}
.down-icon {
  height: 1.1rem;
  color: var(--basecolor);
  cursor: pointer;
  width: 1.1rem;
}
/* .good-performace {
  background-color: #ddfaea;
  border-radius: 0.3rem;
  padding: 0.3rem;
  color: #1eba66;
  font-family: "poppins", sans-serif !important;
  height: 30px;
}

.bad-performace {
  background-color: #ffeaea;
  border-radius: 0.3rem;
  padding: 0.3rem;
  color: #fd5353;
  font-family: "poppins", sans-serif !important;
  height: 30px;
} */
.profile-form > h5 {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 13px !important;
  padding: 1rem 1rem;
  color: var(--basecolor);
  border-bottom: 1px solid #ddd;
}
.register-box {
  border-bottom: 1px solid #ddd;
}
.registration-block > div > h5 {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 13px !important;
  padding: 1rem 1rem;
  color: var(--basecolor);
}

.registration-setup {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 1rem;
  gap: 1rem;
}
.registration-setup > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.registration-setup label {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  color: var(--textcolor) !important;
}

.confirmation-check > p {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  color: var(--textcolor) !important;
}
.confirmation-check > span {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  color: #4d44b5 !important;
}
.submit-register-block {
  background-color: var(--basecolor);
  padding: 0.5rem 0.8rem;
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
}
.submit-btn-register {
  background-color: var(--basecolor);
  border: none;
  color: white;
  font-family: "poppins", sans-serif;
  border-radius: 0.5em;
  font-size: 14px;
}
.registration-setup input {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  height: 40px !important;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}
.profile-form select {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}
.select-block {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  height: 40px !important;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}

.select-block:focus {
  box-shadow: none !important;
  outline: none !important;
}

.profile-save {
  border: none !important;
  background-color: var(--basecolor) !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.submit-btn {
  float: right;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none !important;
  background-color: var(--basecolor) !important;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.save-border {
  border-top: 1px solid #ddd;
}
.profile-parent-card > div > img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}
.profile-parent-card > div {
  position: relative;
}
.profile-parent-card {
  min-height: 322px;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.profile-parent-card p {
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
  margin: 0;
  padding: 0;
}
.profile-parent-card span {
  font-family: "poppins light", sans-serif !important;
  font-size: 13px !important;
  margin: 0;
  padding: 0;
}
.camera-icon-block {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  bottom: 0px;
  background-color: #395ee2;
  border-radius: 100%;
  padding: 0.3rem;
  color: white;
  border: 2px solid white;
}
.camera-icon {
  cursor: pointer;
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 15px !important;
}
.hide-input {
  width: 0;
  opacity: 0;
}
