.class-card {
  position: relative;
  border-radius: 0.5em;
  background-color: white;
  height: 100%;
  overflow: hidden;
}
.class-card-box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100%;
  padding: 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5em;
}
.item-title {
  cursor: pointer;
  color: var(--basecolor);
}
.class-card > div > img {
  width: 100%;
  object-fit: cover;
}
.class-card .price {
  margin: 10px 0;
  background-color: var(--basecolor);
  padding: 0.3rem 0.5rem;
  color: white;
  font-family: "poppins", sans-serif !important;
  font-size: 13px !important;
}
.class-card span {
  font-family: "poppins light", sans-serif !important;
  padding: 0;
  margin: 0;
  font-size: 13px ;
}

.class-card > div > p {
  font-family: "poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  font-size: 1rem ;
}
.batch-icon {
  top: 10px;
  color: white;
  transform: rotate(-45deg);
  height: 15px;
  left: -30px;
  background-color: var(--basecolor);
  position: absolute;
  width: 100px;
  overflow: hidden;
}
.class-describe {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.no-hover tbody {
  background-color: transparent !important;
}

.no-hover tbody tr,
.no-hover tbody tr:hover,
.no-hover tbody tr td {
  font-family: "poppins light", sans-serif !important;
  background-color: transparent !important;
}


