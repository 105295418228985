.profile-describe-block > div {
  flex: 1 1;

  display: flex;
  gap: 1rem;
}

.profile-describe-block {
  display: flex;

  gap: 1rem;
  margin-top: 1rem;
}

.cssbuttons-io-button.disabled {
  pointer-events: none;
  opacity: 0.5;
}
